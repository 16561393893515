import React, { useState, useEffect } from 'react'
import AboutOpinion from './Components/About/AboutOpinion'
import AboutPart from './Components/About/AboutPart'
import Header from './Components/Header'

function About () {
  return (
    <>
      <Header url={'about'} />
      <AboutPart />
      <AboutOpinion />
    </>
  )
}
export default About
