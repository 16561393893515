import React from 'react'
import ContactForm from '../ContactForm'
function ContactInfo () {
  return (
    <>
      <div className='relative z-50'>
        <div className='relative mt-12'>
          <ContactForm />
        </div>
      </div>
    </>
  )
}
export default ContactInfo
