import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ApiUrl } from '../URL/url'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'
export default function AboutComponent () {
  const [error, setError] = useState(null)
  const [content, setContent] = useState([])

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/about?populate[AboutContent][populate]=*`)
      .then(({ data }) => setContent(data.data.attributes.AboutContent))
      .catch(error => setError(error))
  }, [])

  if (error) {
    return <div>An error occured: {error.message}</div>
  }
  const { Title, Description, Image } = content

  return (
    <>
      <Card className='w-full md:flex-row Second__Blog'>
        <CardBody className='transition-all md:w-1/2'>
          <Typography
            variant='h6'
            color='blue'
            className='mb-4 uppercase'
          ></Typography>
          <Typography
            variant='h4'
            color='blue-gray'
            className='mb-2 text-linkColor'
          >
            {content.Title}
          </Typography>
          <Typography
            color='gray'
            className='mb-8 font-normal md:max-w-[90%]'
            dangerouslySetInnerHTML={{ __html: content.Description }}
          ></Typography>
        </CardBody>
        <CardHeader
          shadow={false}
          floated={false}
          className='m-0 transition-all duration-300 rounded-r-none bg-gradient-to-r from-[#172940] to-[#64f]  max-md:px-6 md:w-1/2 shrink-0'
        >
          <img
            src='https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80'
            alt='image'
            className='object-cover w-full h-full'
          />
        </CardHeader>
      </Card>
    </>
  )
}
