import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import WhiteLogo from '../../assets/img/logo/new.png'
import axios from 'axios'
import { ApiUrl } from '../URL/url'
function Footer () {
  const [error, setError] = useState(null)
  const [service, setService] = useState([])
  const [content, setContent] = useState([]);
  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/contact?populate[ContactInfo][populate]=*`)
      .then(({ data }) => setContent(data.data.attributes.ContactInfo))
      .catch((error) => setError(error));
  }, []);
  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/services?populate=*`)
      .then(({ data }) => setService(data.data))
      .catch(error => setError(error))
  }, [])

  if (error) {
    return <div>An error occured: {error.message}</div>
  }
  return (
    <>
      <div className='w-full bg-gradient-to-r font-press-start from-[#172940] relative to-[#64f] before:bg-black  Footer-Section py-10'>
        <div className='py-10 mx-6 text-center md:text-left'>
          <div className='flex flex-wrap max-w-screen-xl mx-auto'>
            <div className='w-[70%] mt-3 justify-center mx-auto sm:mx-0  sm:w-2/4   md:w-1/4 h-full flex sm:justify-start'>
              <img src={WhiteLogo} className='' alt='' />
            </div>
            <div className='w-[70%] mt-3 mx-auto sm:mx-0 justify-start sm:w-2/4   md:w-1/4 text-white font-press-start-lato  flex flex-col space-y-1.5 Link__Hover'>
              <p className=''>
                <Link to='/' className=''>
                  Home
                </Link>
              </p>
              <p className=''>
                <Link to='/about' className=''>
                  About
                </Link>
              </p>
              <p className=''>
                <Link to='/services' className=''>
                  Services
                </Link>
              </p>
              <p>
                <Link to='/projects' className=''>
                  Projects
                </Link>
              </p>
              {/* <p>
                        <Link to="/blog" className="">Blog</Link>
                    </p> */}
              <p>
                <Link to='/contact' className=''>
                  Contact
                </Link>
              </p>
            </div>
            <div className='w-[70%] mt-3 mx-auto sm:mx-0 justify-start sm:w-2/4   md:w-1/4 text-white font-press-start-lato flex flex-col space-y-1.5 Link__Hover'>
            {service &&
            service.map(({ attributes }) => {
              return (
                <>
                  <Link
                    to={`/services/${attributes.slug}`}
                    className=""
                  >
                    <h3 className="text-white font-press-start-lato">
                      {attributes.slug}
                    </h3>
                  </Link>
                </>
              );
            })}
            </div>
            <div className='w-[70%] mt-3 mx-auto sm:mx-0 justify-start sm:w-2/4   md:w-1/4 text-white font-press-start-lato flex flex-col space-y-1.5 Link__Hover'>
            {content &&
            content.map(({ id, Info, InfoHref }) => {
              if (id === 3) {
                return (
                  <>
                    <div className="w-full md:w-[80%] text-white font-press-start-lato">
                      <a
                        href="https://goo.gl/maps/YLVE5vb5RLJgMsNm8"
                        className=""
                      >
                        <p className="flex items-center justify-center mb-4 transition-all duration-300 ease-linear md:justify-start hover:scale-105">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 mr-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                            />
                          </svg>

                          {Info}
                        </p>
                      </a>
                    </div>
                  </>
                );
              } else if (id === 2) {
                return (
                  <>
                    <div className="w-[80%]  text-white font-press-start-lato">
                      <a href={InfoHref}>
                        <p className="flex items-center justify-center mb-4 transition-all duration-300 ease-linear md:justify-start hover:scale-105">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="envelope"
                            className="w-6 mr-4"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                            ></path>
                          </svg>
                          {Info}
                        </p>
                      </a>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="w-[80%] text-white font-press-start-lato">
                      <a href={InfoHref}>
                        <p className="flex items-center justify-center mb-4 transition-all duration-300 ease-linear md:justify-start hover:scale-105">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="phone"
                            className="w-6 mr-4"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                            ></path>
                          </svg>
                          {Info}
                        </p>
                      </a>
                    </div>
                  </>
                );
              }
            })}
            </div>
          </div>
        </div>
        <div className='p-6 text-center text-white border-t border-linkColor font-press-start-lato'>
          <span>
            ©{' '}
            <span id='year' className='mr-2'>
              2023
            </span>
            ABOV LLC
          </span>
        </div>
      </div>
    </>
  )
}

export default Footer
