import React from "react";
import BlogContent from "./Components/Blog/BlogContent";
import Header from "./Components/Header";

function Blog(){
    return(
        <>
            <Header />
            <BlogContent />
        </>
    )
}
export default Blog