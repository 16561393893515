import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ApiUrl } from '../../URL/url'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'

function ServiceMap () {
  const [error, setError] = useState(null)
  const [service, setService] = useState([])

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/services?populate=*`)
      .then(({ data }) => setService(data.data))
      .catch(error => setError(error))
  }, [])
  console.log(service)
  if (error) {
    return <div>An error occured: {error.message}</div>
  }

  return (
    <>
      {service &&
        service.map(({ id, attributes }) => {
          return (
            <>
              <Card className='w-full md:flex-row Second__Blog max-w-screen-xl mx-auto flex flex-wrap justify-center space-x-0  z-20 border my-10 overflow-hidden bg-[#F0F4F9] rounded-3xl'>
                <CardBody className='transition-all md:w-1/2'>
                  <Typography
                    variant='h6'
                    color='blue'
                    className='mb-4 uppercase'
                  ></Typography>
                  <Typography
                    color='gray'
                    className='mb-8 text-lg font-bold text-white font-press-start-lato'
                    dangerouslySetInnerHTML={{ __html: attributes.Content }}
                  ></Typography>
                </CardBody>
                <CardHeader
                  shadow={false}
                  floated={false}
                  className='m-0 transition-all duration-300 rounded-r-none bg-gradient-to-r from-[#172940] to-[#64f]  max-md:px-6 md:w-1/2 shrink-0'
                >
                  <img
                    src={`${ApiUrl}${attributes.img.data.attributes.url}`}
                    className={'h-48 w-48  md:h-full md:w-full'}
                  />
                </CardHeader>
              </Card>
            </>
          )
        })}
    </>
  )
}
export default ServiceMap
