import React, { useEffect, useState } from 'react'
import { ApiUrl } from '../../URL/url'
import axios from 'axios'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'
function PortfolioContent () {
  const [error, setError] = useState(null)
  const [portfolio, setPortfolio] = useState([])

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/portfolios?populate=*`)
      .then(({ data }) => setPortfolio(data.data))
      .catch(error => setError(error))
  }, [])

  if (error) {
    return <div>An error occured: {error.message}</div>
  }

  return (
    <>
      <div className='max-w-screen-xl py-10 mx-auto rounded-3xl Shadow'>
        {portfolio &&
          portfolio.map(({ id, attributes }) => {
            return (
              <>
                {id % 2 != 0 ? (
                  <Card
                    className='w-full mt-12 overflow-hidden shadow-2xl md:flex-row rounded-3xl'
                    key={id}
                  >
                    <CardBody className='md:w-1/2 max-md:h-72'>
                      <Typography
                        variant='h4'
                        color='blue-gray'
                        className='mb-2 text-xl font-bold text-linkColor md:text-4xl font-press-start'
                      >
                        {attributes.Title}
                      </Typography>
                      <Typography
                        color='gray'
                        className='mb-8  text-black font-bold text-1.5xl  font-press-start-lato'
                        dangerouslySetInnerHTML={{ __html: attributes.Desc }}
                      ></Typography>
                      <a
                        href={`${attributes.ProjectHref}`}
                        target={'_blank'}
                        className='link-button-dark'
                      >
                        See More
                      </a>
                    </CardBody>
                    <CardHeader
                      shadow={false}
                      floated={false}
                      className='m-0 rounded-r-none md:w-1/2 shrink-0 h-80'
                    >
                      <img
                        src={`${ApiUrl}${attributes.Img.data.attributes.url}`}
                        alt={attributes.Title}
                        className={
                          'hover:translate-y-[-80%] ease-linear transition-all duration-[3s]'
                        }
                      />
                    </CardHeader>
                  </Card>
                ) : (
                  <Card
                    className='w-full mt-12 overflow-hidden shadow-2xl md:flex-row rounded-3xl'
                    key={id}
                  >
                    <CardHeader
                      shadow={false}
                      floated={false}
                      className='m-0 rounded-r-none md:w-1/2 shrink-0 h-80'
                    >
                      <img
                        src={`${ApiUrl}${attributes.Img.data.attributes.url}`}
                        alt={attributes.Title}
                        className={
                          'hover:translate-y-[-80%] ease-linear transition-all duration-[3s]'
                        }
                      />
                    </CardHeader>
                    <CardBody className='text-right md:w-1/2 max-md:h-72'>
                      <Typography
                        variant='h4'
                        color='blue-gray'
                        className='mb-2 text-xl font-bold text-linkColor md:text-4xl font-press-start'
                      >
                        {attributes.Title}
                      </Typography>
                      <Typography
                        color='gray'
                        className='mb-8  text-black font-bold text-1.5xl  font-press-start-lato'
                        dangerouslySetInnerHTML={{ __html: attributes.Desc }}
                      ></Typography>
                      <Typography variant='div' className='flex justify-end'>
                        <a
                          href={`${attributes.ProjectHref}`}
                          target={'_blank'}
                          className='link-button-dark'
                        >
                          See More
                        </a>
                      </Typography>
                    </CardBody>
                  </Card>
                )}
              </>
            )
          })}
      </div>
    </>
  )
}

export default PortfolioContent
