import { useState, useEffect } from 'react'
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton , 
  Collapse
} from '@material-tailwind/react'
import AbovLogo from '../../assets/img/logo/abov.svg'
import { Link } from 'react-router-dom'

export default function Header () {
  const [openNav, setOpenNav] = useState(false)

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false)
    )
  }, [])

  const navList = (
    <ul className='flex flex-col gap-2 mt-2 mb-4 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6'>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link to='/about' className='flex items-center header-link'>
          About
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link to='/services' className='flex items-center header-link'>
          Services
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link to='/projects' className='flex items-center header-link'>
          Projects
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link to='/contact' className='flex items-center header-link'>
          Contact
        </Link>
      </Typography>
    </ul>
  )

  return (
    <Navbar className='max-w-screen-xl px-4 py-2 mx-auto border-none lg:px-8 lg:py-4'>
      <div className='container flex items-center justify-between mx-auto text-blue-gray-900'>
        <Typography className=''>
          <Link to={''}>
            <img
              src={AbovLogo}
              className={'w-24'}
              alt='AbovAcademy WebProgramming'
            />
          </Link>
        </Typography>
        <div className='hidden lg:block'>{navList}</div>
        <IconButton
          variant='text'
          className='w-6 h-6 ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden'
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              className='w-6 h-6'
              viewBox='0 0 24 24'
              stroke='#172940'
              strokeWidth={2}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'
                fill='black'
              />
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-6 h-6'
              fill='black'
              stroke='#172940'
              strokeWidth={2}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M4 6h16M4 12h16M4 18h16'
              />
            </svg>
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <div className='container mx-auto'>{navList}</div>
      </Collapse>
    </Navbar>
  )
}
