import React from 'react'
function ContactAddressPart () {
  return (
    <>
      <div className=' max-w-screen-xl  mx-auto my-10  bg-[#F0F4F9] rounded-3xl overflow-hidden Shadow  h-96'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3044.230611948017!2d44.63217111564566!3d40.27062717243573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406aa135314364f3%3A0xe0e128cc39cfa145!2sAbovAcademy!5e0!3m2!1sen!2s!4v1676286961592!5m2!1sen!2s'
          className='w-full h-96 rounded-3xl'
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>
    </>
  )
}
export default ContactAddressPart
