import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { ApiUrl } from '../URL/url'

function Header ({ url }) {
  const [error, setError] = useState(null)
  const [content, setContent] = useState([])
  const [links, setLinks] = useState([])
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/${url}?populate[TopHeader][populate]=*`)
      .then(({ data }) => setContent(data.data.attributes.TopHeader))
      .catch(error => setError(error))
  }, [])

  console.log(content)

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/${url}?populate[TopHeader][populate]=*`)
      .then(({ data }) => setLinks(data.data.attributes.TopHeader.Links))
      .catch(error => setError(error))
  }, [])

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/${url}?populate[TopHeader][populate]=*`)
      .then(({ data }) =>
        setImageUrl(data.data.attributes.TopHeader.image.data.attributes)
      )
      .catch(error => setError(error))
  }, [])

  if (error) {
    return <div>An error occurred : {error.message}</div>
  }

  return (
    <>
      <div
        className='z-20 max-w-screen-xl mx-auto overflow-hidden border Second__Blog'
        id='Second__Home__Page_Blog'
      >
        <div className='relative z-30 flex flex-col justify-center space-y-8 font-bold max-md:py-12 second_for_text md:w-2/4 '>
          <h1 className='px-5 text-2xl text-linkColor xl:text-5xl lg:text-4xl md:text-3xl sm:text-3xl'>
            {content.Title}
          </h1>
          <div
            className='px-5 text-gray-100 text-md sm:text-xl font-press-start-lato'
            dangerouslySetInnerHTML={{ __html: content.Desc }}
          ></div>
          <div className='flex mx-5 space-x-8'>
            {links &&
              links.map(({ LinkText, LinkHref }) => {
                return (
                  <React.Fragment key={LinkHref}>
                    <Link to={`/${LinkHref}`} className='link-button'>
                      {LinkText}
                    </Link>
                  </React.Fragment>
                )
              })}
          </div>
        </div>
        <div className='relative z-30 flex justify-center second_for_image md:w-2/4 bg-[#172940]'>
          <img
            src={`${ApiUrl}${imageUrl.url}`}
            className='w-72 md:w-auto'
            alt=''
          />
        </div>
      </div>
    </>
  )
}
export default Header
