import React, { useEffect, useState } from "react";
import Header from "./Components/Header";
import HomeAbout from "./Components/Home/HomeAbout";
import HomeAfterRotate from "./Components/Home/homeAfterrotate";
import HomeCounter from "./Components/Home/HomeCounter";
import HomeSceens from "./Components/Home/homeSceens";
function Home() {
  return (
    <>
      <Header url='home' />
      <HomeAbout />
      <HomeSceens />
      <HomeCounter />
      <HomeAfterRotate />
    </>
  );
}
export default Home;
