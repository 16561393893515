import React from 'react'
import AboutComponent from '../AboutComponent'

function AboutPart () {
  return (
    <>
      
      <div className='max-w-screen-xl mx-auto my-12 overflow-hidden rounded-r-3xl'>
        <AboutComponent />
      </div>
      
    </>
  )
}
export default AboutPart
