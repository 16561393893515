import React from "react";
import Header from "./Components/Header";
import ServiceMap from "./Components/Service/ServiceMap";

function Services() {
  return (
    <>
      <Header url="service-single"/>
      <ServiceMap />
    </>
  );
}
export default Services;
