import React from "react";
import Header from "./Components/Header";
import PortfolioContent from "./Components/Portfolio/PortfolioContent";
function Portfolio() {
  return (
    <>
      <Header url='portfolio-single' />
      <PortfolioContent />
    </>
  );
}
export default Portfolio;
