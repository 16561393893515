import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from "../Header";
import { ApiUrl } from "../../URL/url";

function ServiceSingle() {
  const { slug } = useParams();
  const [error, setError] = useState(null);
  const [content, setContent] = useState([]);

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/services/${slug}`)
      .then(({ data }) => setContent(data.data.attributes))
      .catch((error) => setError(error));
  }, []);

  if (error) {
    return <div>An error occured: {error.message}</div>;
  }

  return (
    <>
      <Header url={`services/${slug}`} />      
      <div>
        <div
          className="max-w-screen-xl mx-auto my-10 py-5 bg-[#F0F4F9] rounded-3xl px-12 Shadow overflow-auto h-48 sm:h-auto scrollbar-thin  scrollbar-thumb-linkColor scrollbar-thumb  scrollbar-track-transparent scrollbar-thumb-rounded-full   sm:overflow-visible"
        >
          <h1 className="font-bold text-2xl font-press-start">
            {content.slug}
          </h1>
          <p
            className="font-medium font-press-start-lato mt-5"
            dangerouslySetInnerHTML={{ __html: content.Desc }}
          />
        </div>
      </div>
      {/* {
                items.current_service && items.current_service.map((itm, index) => {
                    if (slag == itm.slag) {
                        return <div className="max-w-screen-xl mx-auto my-10 py-5 bg-[#F0F4F9] rounded-3xl px-12 Shadow overflow-auto h-48 sm:h-auto scrollbar-thin  scrollbar-thumb-linkColor scrollbar-thumb  scrollbar-track-transparent scrollbar-thumb-rounded-full   sm:overflow-visible" key={index}>
                            <h1 className="font-bold text-2xl font-press-start">{itm.current_service_title}</h1>
                            <p className="font-medium font-press-start-lato mt-5" dangerouslySetInnerHTML={{ __html: itm.current_service_desc }} />
                        </div>
                    }

                })
            } */}
    </>
  );
}
export default ServiceSingle;
