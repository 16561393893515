import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AbovWhite from '../../../assets/img/logo/LLC-White.svg'
import axios from 'axios'
import { ApiUrl } from '../../URL/url'
import AboutComponent from '../AboutComponent'

function HomeAbout () {
  const [error, setError] = useState(null)
  const [content, setContent] = useState([])

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/about?populate[AboutContent][populate]=*`)
      .then(({ data }) => setContent(data.data.attributes.AboutContent))
      .catch(error => setError(error))
  }, [])

  if (error) {
    return <div>An error occured: {error.message}</div>
  }
  const { Title, Description, Image } = content
  return (
    <>
      <div
        className='z-20 max-w-screen-xl mx-auto overflow-hidden border Second__Blog'
        id='Second__Home__Page_Blog'
      >
        <AboutComponent />
      </div>
    </>
  )
}
export default HomeAbout
