import React from "react";
import { Routes, Route } from "react-router-dom";

import "./index.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import About from "./Pages/about";
import Blog from "./Pages/blog";
import BlogSingle from "./Pages/Components/Blog/BlogSingle";
import Contact from "./Pages/contact";
import Error from "./Pages/404";
import Footer from "./Pages/layouts/footer";
import Header from "./Pages/layouts/header";
import Home from "./Pages/home";
import Portfolio from "./Pages/portfolio";
import Services from "./Pages/services";
import ServiceSingle from "./Pages/Components/Service/ServiceSingle";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services">
          <Route index element={<Services />} />
          <Route path="/services:slug" element={<ServiceSingle />} />
        </Route>
        <Route path="/projects" element={<Portfolio />} />
        <Route path="/blog">
          <Route index element={<Blog />} />
          <Route path="/blog:id" element={<BlogSingle />} />
        </Route>
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
