import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import emailjs from "@emailjs/browser";
import { ApiUrl } from "../URL/url";
import $ from "jquery";
function ContactForm() {
  const form = useRef();
  const [error, setError] = useState(null);
  const [content, setContent] = useState([]);

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/contact?populate[ContactInfo][populate]=*`)
      .then(({ data }) => setContent(data.data.attributes.ContactInfo))
      .catch((error) => setError(error));
  }, []);

  useEffect(() => {
    $('.form-input').on('keyup paste cut focusout', function(){
      var $parent = $(this).parents('.form-line');
      var input_value = $.trim($(this).val());
      var required = $(this).prop('required');

      if (input_value.length > 0) {
        $parent.find('label').addClass('top');

        $parent
          .removeClass('error')
          .addClass('success');
      } else {
        $parent.find('label').removeClass('top');
        $parent.removeClass('success')

        if (required) {
          $parent.addClass('error');
        }
      }
    });
  }, []);

  if (error) {
    return <div>An error occured: {error.message}</div>;
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ylg1z7j",
        "template_kcwx3nq",
        form.current,
        "7rEmscGWI0KKCHRSx"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
  };

  return (
    <>
      <div className=" max-w-screen-xl mx-auto rounded-3xl relative z-50 bg-[#172940] flex flex-col md:flex-row md:border-2 md:border-linkColor">
        <div className="flex flex-col items-center justify-center w-full mt-10 space-y-3 md:w-1/2 md:mt-0">
          {content &&
            content.map(({ id, Info, InfoHref }) => {
              if (id === 3) {
                return (
                  <>
                    <div className="w-full md:w-[80%] text-white font-press-start-lato">
                      <a
                        href="https://goo.gl/maps/YLVE5vb5RLJgMsNm8"
                        className=""
                      >
                        <p className="flex items-center justify-center mb-4 transition-all duration-300 ease-linear md:justify-start hover:scale-105">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 mr-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                            />
                          </svg>

                          {Info}
                        </p>
                      </a>
                    </div>
                  </>
                );
              } else if (id === 2) {
                return (
                  <>
                    <div className="w-[80%]  text-white font-press-start-lato">
                      <a href={InfoHref}>
                        <p className="flex items-center justify-center mb-4 transition-all duration-300 ease-linear md:justify-start hover:scale-105">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="envelope"
                            className="w-6 mr-4"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                            ></path>
                          </svg>
                          {Info}
                        </p>
                      </a>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="w-[80%] text-white font-press-start-lato">
                      <a href={InfoHref}>
                        <p className="flex items-center justify-center mb-4 transition-all duration-300 ease-linear md:justify-start hover:scale-105">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="phone"
                            className="w-6 mr-4"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                            ></path>
                          </svg>
                          {Info}
                        </p>
                      </a>
                    </div>
                  </>
                );
              }
            })}
        </div>
        <div className="w-full md:w-1/2 ">
          <div className="containerS font-press-start-lato">
            <form className="form-container" ref={form} onSubmit={sendEmail}>
              <div className="headline">
                <span className="text-3xl text-white">Contact Us</span>
              </div>
              <div className="form-line">
                <input
                  type="text"
                  className="form-input"
                  name="name"
                  required
                />
                <label className="">Name *</label>
                <div className="error-label">Field is required!</div>
                <div className="check-label"></div>
              </div>
              <div className="form-line">
                <input
                  type="email"
                  className="form-input"
                  name="email"
                  required
                />
                <label>Your email *</label>
                <div className="error-label">Field is required!</div>
                <div className="check-label"></div>
              </div>
              <div className="form-line">
                <textarea
                  className="form-input"
                  name="message"
                  required
                ></textarea>
                <label>Message *</label>
                <div className="check-label"></div>
                <div className="error-label">Field is required!</div>
              </div>

              <input
                type="submit"
                className="form-button rounded-3xl"
                value="Send"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContactForm;
