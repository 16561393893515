import React, { useState, useEffect } from 'react'
import ContactForm from '../ContactForm'

function HomeAfterRotate () {
  return (
    <>
      <div className='relative z-50 w-full mx-auto mt-10 mb-10 overflow-hidden rounded-3xl md-10 md:mb-0 md:rounded-none Last-Section'>
        <div className='relative mt-0 sm:mt-4 After__Rotate__Effect'>
          <ContactForm />
        </div>
      </div>
    </>
  )
}
export default HomeAfterRotate
