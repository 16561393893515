import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import { ApiUrl } from "../../URL/url";

function AboutOpinion() {
  const [error, setError] = useState(null);
  const [content, setContent] = useState([]);

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/about?populate[Reviews][populate]=*`)
      .then(({ data }) => setContent(data.data.attributes.Reviews))
      .catch((error) => setError(error));
  }, []);

  if (error) {
    return <div>An error occured: {error.message}</div>;
  }

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows:false
        },
      },
    ],
  };

  return (
    <>
      <div className="max-w-screen-xl mx-auto my-10 py-10 bg-[#F0F4F9]  relative  rounded-3xl  Shadow">
        <h1 className="About_Title">What Our Clients Say About Us</h1>
        <Slider {...settings} className={"py-10"}>
          {content &&
            content.map(({ Desc, Href, Title }) => {
              return (
                <>
                  <div className={"px-4"}>
                    <div className="flex flex-col justify-between h-48 py-4 Slider_Part">
                      <div
                        className="font-medium text-black font-press-start-lato text-md"
                        dangerouslySetInnerHTML={{ __html: Desc }}
                      ></div>
                      <p className="mt-3">
                        <a
                          href={Href}
                          target={"_blank"}
                          className={
                            "text-linkColor font-bold font-press-start"
                          }
                        >
                          {Title}
                        </a>
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
        </Slider>
      </div>
    </>
  );
}

export default AboutOpinion;
