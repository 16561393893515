import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import { ApiUrl } from '../../URL/url'
import axios from 'axios'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'
function HomeSceens () {
  const [error, setError] = useState(null)
  const [portfolio, setPortfolio] = useState([])

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/portfolios?populate=*`)
      .then(({ data }) => setPortfolio(data.data))
      .catch(error => setError(error))
  }, [])
  console.log(portfolio)
  if (error) {
    return <div>An error occured: {error.message}</div>
  }

  let settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    appendDots: dots => (
      <div
        style={{
          borderRadius: '10px',
          padding: '10px',
          bottom: '-60px',
          height: '40px',
          fontSize: '12px'
        }}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    )
  }

  return (
    <>
      <div className='Section_for_round'>
        <div className='max-w-screen-xl mx-auto '>
          <Slider {...settings} className={'bg-white rounded-3xl'}>
            {portfolio &&
              portfolio.map(({ id, attributes }) => {
                return (
                  <>
                    <Card
                      className='w-full overflow-hidden md:flex-row rounded-3xl'
                      key={id}
                    >
                      <CardBody className='md:w-1/2 max-md:h-72'>
                        <Typography
                          variant='h4'
                          color='blue-gray'
                          className='mb-2 text-xl font-bold text-linkColor md:text-4xl font-press-start'
                        >
                          {attributes.Title}
                        </Typography>
                        <Typography
                          color='gray'
                          className='mb-8  text-black font-bold text-1.5xl  font-press-start-lato'
                          dangerouslySetInnerHTML={{ __html: attributes.Desc }}
                        ></Typography>
                        <a
                          href={`${attributes.ProjectHref}`}
                          target={'_blank'}
                          className='link-button-dark'
                        >
                          See More
                        </a>
                      </CardBody>
                      <CardHeader
                        shadow={false}
                        floated={false}
                        className='m-0 rounded-r-none md:w-1/2 shrink-0 h-80'
                      >
                        <img
                          src={`${ApiUrl}${attributes.Img.data.attributes.url}`}
                          alt={attributes.Title}
                          className={
                            'hover:translate-y-[-80%] ease-linear transition-all duration-[3s]'
                          }
                        />
                      </CardHeader>
                    </Card>
                  </>
                )
              })}
          </Slider>
        </div>
      </div>
    </>
  )
}
export default HomeSceens
