import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../../URL/url";

function HomeCounter() {
  const [error, setError] = useState(null);
  const [service, setService] = useState([]);

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/services?populate=*`)
      .then(({ data }) => setService(data.data))
      .catch((error) => setError(error));
  }, []);

  if (error) {
    return <div>An error occured: {error.message}</div>;
  }

  return (
    <>
      <div className="Service__Blog">
        <div className="relative z-30 flex flex-col space-y-4 md:w-1/2 md:mt-0">
          {service &&
            service.map(({ attributes }) => {
              return (
                <>
                  <Link
                    to={`/services/${attributes.slug}`}
                    className="Home__Counter__Child"
                  >
                    <h3 className="Home__Counter__Child__Title">
                      {attributes.slug}
                    </h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className=" Link__Svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </Link>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
}
export default HomeCounter;
