import React , {useState,useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
function BlogContent() {
    
    // const [items, setitems] = useState([]);
    // useEffect(() => {
    //     axios.get(`https://9jeofvls.directus.app/items/Blog`).then(
    //         res => setitems(res.data.data)
    //     )
    // }, [])

    return (
        <>

             <div className="w-[90%] max-w-screen-xl flex mx-auto my-20 py-5  rounded-3xl">
                <div className="w-full lg:w-2/3 flex flex-wrap justify-between">
                   
                    {
                        // items && items.map((itm , index) => {
                        //     return  <Link to={`/blogSingle/${itm.id}`} className="w-[100%] border  mt-3 md:w-[48%] rounded-2xl overflow-hidden"> 
                        //     <div className="" key={index}>
                               
                        //     <div className="group">
                        //         <div className="overflow-hidden h-64 border w-full cursor-pointer">
                        //             <img src={`https://9jeofvls.directus.app/assets/${itm.blog_image}`} className={'transition-all duration-300 group-hover:scale-105 w-full h-full object-cover'} />
                        //         </div>
                        //         <div className="p-2">
                        //             <Link to={`/blogSingle/${itm.id}`} className="font-bold text-black font-press-start">
                        //             {itm.blog_title}
                        //             </Link>
                        //             {/* <h3 className="font-bold text-black font-press-start">{itm.blog_title}</h3> */}
                        //             <p  className={'text-linkColorDark font-bold font-press-start-lato'}>{itm.summary}</p>
                        //         </div>
                        //     </div>

                        // </div>
                        // </Link>
                        // })
                    } 
                  
                </div>
                <div className="hidden px-1.5 lg:block w-1/3 mt-3 ">
                   <div className=" bg-[#F0F4F9] p-6 pt-1 rounded-r-3xl">
                   <div>
                        <h3 className="font-bold font-press-start text-xl">RECENT POSTS</h3>
                    </div>
                    <div className="flex flex-col space-y-3">
                        {
                            // items && items.map((itms , index) => {
                            //     return <Link to={`/blogSingle/${itms.id}`} className={'text-xl font-bold text-linkColor font-press-start-lato'} key={index}>
                            //     Recent Post
                            // </Link>
                            // })
                        }
                   
                    </div>
                   </div>
                </div>
            </div>
        </>
    )
}
export default BlogContent