import React from 'react'
import Header from './Components/Header'
import ContactInfo from './Components/Contact/contactinfo'
import ContactHeader from './Components/Contact/ContactHeader'
import ContactAddressPart from './Components/Contact/ContactAddressPart'
function Contact () {
  return (
    <>
      <Header url='contact' />
      <ContactInfo />
      <ContactAddressPart />
    </>
  )
}
export default Contact
